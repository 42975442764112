import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import TopNav from '../component/TopNav'; 
import CourseSectionTopTitle from '../component/CourseSectionTopTitle'; 
import CourseVideo from '../component/CourseVideo'; 
import CourseSectionMarkdown from '../component/CourseSectionMarkdown'; 
import CourseChapterList from '../component/CourseChapterList';
import { toast } from '../util/Function';
import { Button, Overlay, Spinner } from "@blueprintjs/core";

@withRouter
@inject("store")
@observer
export default class CourseSectionShow extends Component
{
    state = {"section":false,"count_down":false};
    
    async componentDidMount()
    {
        await this.loadSection();
    }

    async componentDidUpdate( prevProps )
    {
        if( this.props.match.params.id !=  prevProps.match.params.id)
        await this.loadSection();
    }

    async loadSection()
    {
        const id = this.props.match.params.id; 
        if( id > 0 )
        {
            const section = await this.props.store.get_section_content( id );
            if( section ) this.setState({section});
            // console.log( this.state );

            // 播放10秒再保存进度
            window.setTimeout( ()=>window.localStorage.setItem("KT_last_section_"+section.course.id,id), 10*1000 );
            
        } 
    }

    goNext( id )
    {
        console.log( "go next" , id );
        // toast(id);
        this.count_down_hd = window.setTimeout( ()=>{
            this.setState({"count_down":false});
            //this.props.history.push("/section/show/"+id);
            window.location= "/section/show/"+id;
        } , 3000 );
        this.setState({"count_down":true});
    }

    cancelNext()
    {
        window.clearInterval( this.count_down_hd );
        this.setState({"count_down":false});
    }

    sectionFinished()
    {
        let next_section_id = 0;
        // 查找下一节
        if( this.state.section && this.state.section.index )
        {
            // 首先把所有章的小节按顺序放入
            let sections = [];
            Object.values(this.state.section.index).forEach( item => 
            {
                if( item.sections )
                {
                    Object.values(item.sections).forEach( item2 =>
                    {
                        sections.push( item2 ); 
                    })
                }
            });

            // 开始查找
            let next = false;
            let breakit = false;
        
            sections.forEach( item3 => 
            {
                if( breakit ) return ;
                if( next )
                {
                    next_section_id = item3.id;
                    breakit = true;
                }
                else
                {
                    if( this.state.section.id == item3.id ) next = true;
                    else next = false;
                }
                 
            })

            if( next_section_id > 0 )
                this.goNext( next_section_id );
            else
            {
                window.localStorage.removeItem("KT_last_section_"+this.section.course.id);
            }    
        }
        
    }

    render()
    {
        const section = this.state.section;
        if( !section ) return null;
        const main = <div>
            <TopNav />
            <div className="page-box">
            <CourseSectionTopTitle data={section}/>
            { section.media_type == 2 && <>
                <CourseVideo section_id={section.id} data={section} onEnded={()=>this.sectionFinished()}/>
                <CourseSectionMarkdown data={section.markdown} />
            </> }
            { section.index && <CourseChapterList data={section.index} active={section.id}/> }
            </div>
            
            <Overlay hasBackdrop={false} isOpen={this.state.count_down} onClose={()=>this.cancelNext()}>
                <div className="ft-countdown-box">
                <div className="spinner">
                    <Spinner />
                </div>
                <h3>即将转向下一节</h3>
                <Button onClick={()=>this.cancelNext()} large={true} minimal={true}>取消</Button>
                </div>
                
            </Overlay>

        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}