import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import Footer from '../component/Footer'; 
import TopNav from '../component/TopNav'; 
import HeroSubscribeBox from '../component/HeroSubscribeBox'; 

@withRouter
@inject("store")
@observer
export default class Subscribe extends Component
{
    
    async componentDidMount()
    {
        const user = await this.props.store.refresh_user();
        // console.log( user );
    }
    
    render()
    {
        const main = <div>
        <TopNav/>
        <div className="p-60">本站已停止更新，请访问<a href="https://stack.ftqq.com/?mtm_campaign=ding">新版课程网站</a>订阅</div>
        <Footer/>
    </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}