import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Card, Elevation } from "@blueprintjs/core";

@withRouter
@inject("store")
@observer
export default class CourseCard extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        return this.props.data ? <Card className="course-card" interactive={true} elevation={Elevation.TWO} onClick={()=>window.open("/course/detail/"+this.props.data.id,"_blank")}>
        <div className={"cover ft-bg-"+this.props.data.color}>
            <img src={this.props.data.cover_url} alt={this.props.data.title}/>
        </div>
        <div className="content">
        <div className="title">{this.props.data.title}</div>
        <div className="subtitle">{this.props.data.subtitle}</div>
        </div>
        <div className="actionbar">
            <div className="left">
            { this.props.store.subcribe_mode ? '开始学习' : ( this.props.show_price ? '¥'+(this.props.data.price_cent/100).toFixed(2) : '开始学习' ) }

            
                
            </div>
            <div className="right">{this.props.data.author.nickname} 主讲</div>
        </div>
    
    </Card>: null;
    }
}